import type React from 'react';

import type { DatabaseEnum } from '@voyage-lab/db';
import type { IconWrapper } from '@voyage-lab/ui';

export const EventTypeToDetails: Record<
	DatabaseEnum['t_workflow_state_change_event_state'],
	Pick<React.ComponentProps<typeof IconWrapper>, 'name' | 'color' | 'backgroundColor'> & {
		title: string;
		description?: string;
	}
> = {
	started: {
		name: 'Play',
		color: 'primary-icon',
		backgroundColor: 'primary-outline',
		title: 'Abandoned Checkout',
		description: 'Customer abandoned their checkout',
	},
	completed: {
		name: 'CreditCardX',
		color: 'green',
		backgroundColor: 'green-100',
		title: 'Purchased',
		description: 'Customer purchased the product',
	},
	converted: {
		name: 'CursorClick',
		color: 'yellow',
		backgroundColor: 'yellow-100',
		title: 'Converted Sale',
		description: 'Customer converted their sale',
	},
	triggered: {
		name: 'Play',
		color: 'primary-icon',
		backgroundColor: 'primary-outline',
		title: 'Triggered Workflow',
		description: 'Workflow was triggered',
	},
	filtered_out: {
		name: 'Filter',
		color: 'red',
		backgroundColor: 'red-100',
		title: 'Filtered Out',
		description: 'Customer was filtered out',
	},
	exited_early: {
		name: 'LogOut',
		color: 'red',
		backgroundColor: 'red-100',
		title: 'Exited Early',
		description: 'Customer exited the conversation early',
	},
	link_clicked: {
		name: 'Link01',
		color: 'cyan-icon',
		backgroundColor: 'cyan-outline',
		title: 'Clicked Link',
		description: 'Customer clicked a link',
	},
	contact_captured: {
		name: 'User',
		color: 'cyan-icon',
		backgroundColor: 'cyan-outline',
		title: 'Contact Captured',
		description: 'Customer captured their contact',
	},
	in_progress: {
		name: 'DotsHorizontal',
		color: 'orange',
		backgroundColor: 'orange-100',
		title: 'In Progress',
		description: 'Conversation is in progress',
	},
	channel_missing: {
		name: 'AlertTriangle',
		color: 'red',
		backgroundColor: 'red-100',
		title: 'Channel Missing',
		description: 'Customer is missing a channel',
	},
};
